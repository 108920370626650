<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Inputs">
                    <template v-slot:description>
                        <div>import { HbAutocomplete, HbCombobox, HbFileInput, HbSelect, HbTextField, HbTextarea } from 'hummingbird-aviary';</div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card class="mt-4 mb-6">
            <template v-slot:title>
                Common Inputs + Code (for comboboxes and file inputs, see other design system pages)
                <v-spacer></v-spacer>
                <hb-btn small @click="validateOpenForm">Test Validation</hb-btn>
            </template>

            <hb-form label="HbTextField" required>
                <HbTextField
                    v-model="textField1"
                    v-validate="'required|max:45|email'"
                    data-vv-scope="default"
                    data-vv-name="email"
                    data-vv-as="Email"
                    :error="errors.has('default.email')"
                    placeholder="Enter Email"
                />
            </hb-form>

            <hb-form label="HbTextField with 'box' prop" required>
                <HbTextField
                    box
                    v-model="textField2"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-name="text-field-box1"
                    data-vv-as="Text Field Box 1"
                    :error="errors.has('default.text-field-box1')"
                    placeholder="Enter Something"
                />
            </hb-form>

            <hb-form label="HbTextField with 'box' and 'prepend-inner-icon' props and 'append' slot content" required>
                <hb-text-field
                    box
                    v-model="textField3"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-name="text-field-box2"
                    data-vv-as="Text Field Box 2"
                    :error="errors.has('default.text-field-box2')"
                    placeholder="Enter Something"
                    prepend-inner-icon="mdi-account"
                >
                    <template v-slot:append>
                        ft
                    </template>
                </hb-text-field>
            </hb-form>

            <hb-form label="HbTextField with 'search' prop" required>
                <HbTextField
                    search
                    v-model="textField4"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-name="text-field-search"
                    data-vv-as="Text Field Search"
                    :error="errors.has('default.text-field-search')"
                    placeholder="Search"
                />
            </hb-form>

            <hb-form label="HbTextField with 'rounded' prop" required>
                <HbTextField
                    rounded
                    v-model="textField5"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-name="text-field-rounded"
                    data-vv-as="Text Field Rounded"
                    :error="errors.has('default.text-field-rounded')"
                    placeholder="Enter Something"
                />
            </hb-form>

            <hb-form label="HbTextField with 'condensed' and 'box' props, 3 different sizes" required full :auto-layout="false" description="Always use preset sizes for any condensed boxes within a sentence.">
                <v-row no-gutters>
                    <div class="hb-inline-sentence-text">
                        For HbTextField, only use x-small size:
                    </div>
                    
                    <HbTextField
                        condensed
                        box
                        x-small
                        v-model="textField6"
                        v-validate="'required|max:45'"
                        data-vv-scope="default"
                        data-vv-name="text-field-condensed-box-x-small"
                        data-vv-as="Text Field Condensed Box X-Small"
                        :error="errors.has('default.text-field-condensed-box-x-small')"
                        placeholder="0"
                    />
                    
                    <div class="hb-inline-sentence-text">
                        small size:
                    </div>
                    
                    <HbTextField
                        condensed
                        box
                        small
                        v-model="textField7"
                        v-validate="'required|max:45'"
                        data-vv-scope="default"
                        data-vv-name="text-field-condensed-box-small"
                        data-vv-as="Text Field Condensed Box Small"
                        :error="errors.has('default.text-field-condensed-box-small')"
                        placeholder="0"
                    />
                        
                    <div class="hb-inline-sentence-text">
                        or medium size:
                    </div>
                    
                    <HbTextField
                        condensed
                        box
                        medium
                        v-model="textField8"
                        v-validate="'required|max:45'"
                        data-vv-scope="default"
                        data-vv-name="text-field-condensed-box-medium"
                        data-vv-as="Text Field Condensed Box Medium"
                        :error="errors.has('default.text-field-condensed-box-medium')"
                    />
                    
                    <div class="hb-inline-sentence-text">
                        within a sentence.
                    </div>
                </v-row>
                <v-row no-gutters>
                    Placeholder for HbTextField expecting just numbers should be: placeholder="0". Placeholder for HbTextField expecting text inputs (i.e. user will input a combination of numbers and letters) should be empty (i.e. no placeholder).
                </v-row>
            </hb-form>

            <hb-form label="HbTextField with 'condensed' and 'box' props, medium size with 'prepend-inner' and 'append' slot content, and 'prepend-inner-icon' prop content" required full :auto-layout="false">
                <v-row no-gutters>
                    <div class="hb-inline-sentence-text">
                        Prepend Inner Slot "$":
                    </div>
                    
                    <hb-text-field
                        condensed
                        box
                        medium
                        v-model="textField9"
                        v-validate="'required|max:45'"
                        data-vv-scope="default"
                        data-vv-name="text-field-condensed-box-medium-prepend-inner-$"
                        data-vv-as="Text Field Condensed Box Medium Prepend Inner $"
                        :error="errors.has('default.text-field-condensed-box-medium-prepend-inner-$')"
                        placeholder="0"
                    >
                        <template v-slot:prepend-inner>
                            $
                        </template>
                    </hb-text-field>
                    
                    <div class="hb-inline-sentence-text">
                        Append Slot "%":
                    </div>
                    
                    <hb-text-field
                        condensed
                        box
                        medium
                        v-model="textField10"
                        v-validate="'required|max:45'"
                        data-vv-scope="default"
                        data-vv-name="text-field-condensed-box-medium-append-%"
                        data-vv-as="Text Field Condensed Box Medium Append %"
                        :error="errors.has('default.text-field-condensed-box-medium-append-%')"
                        placeholder="0"
                    >
                        <template v-slot:append>
                            %
                        </template>
                    </hb-text-field>
                    
                    <div class="hb-inline-sentence-text">
                        Append Slot "ft":
                    </div>
                    
                    <hb-text-field
                        condensed
                        box
                        medium
                        v-model="textField11"
                        v-validate="'required|max:45'"
                        data-vv-scope="default"
                        data-vv-name="text-field-condensed-box-medium-append-ft"
                        data-vv-as="Text Field Condensed Box Medium Append ft"
                        :error="errors.has('default.text-field-condensed-box-medium-append-ft')"
                        placeholder="0"
                    >
                        <template v-slot:append>
                            ft
                        </template>
                    </hb-text-field>
                        
                    <div class="hb-inline-sentence-text">
                        Prepend Inner Icon Prop "mdi-headset":
                    </div>
                    
                    <HbTextField
                        condensed
                        box
                        medium
                        v-model="textField11"
                        v-validate="'required|max:45'"
                        data-vv-scope="default"
                        data-vv-name="text-field-condensed-box-medium-prepend-inner-icon"
                        data-vv-as="Text Field Condensed Box Medium Prepend Inner Icon"
                        :error="errors.has('default.text-field-condensed-box-medium-prepend-inner-icon')"
                        prepend-inner-icon="mdi-headset"
                        placeholder="0"
                    />
                        
                    <div class="hb-inline-sentence-text">
                        and then the end of the sentence.
                    </div>
                </v-row>
            </hb-form>

            <hb-form label="HbSelect" required>
                <HbSelect
                    v-model="selectField1"
                    :items="['Office','Fax']"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-name="phone"
                    data-vv-as="Phone"
                    :error="errors.has('default.phone')"
                    placeholder="Select Phone"
                />
            </hb-form>

            <hb-form label="HbSelect with 'box' prop" required>
                <HbSelect
                    box
                    v-model="selectField2"
                    :items="['Office','Fax']"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-name="select-box"
                    data-vv-as="Select Box"
                    :error="errors.has('default.select-box')"
                    placeholder="Select Something"
                />
            </hb-form>

            <hb-form label="HbSelect with 'rounded' and 'prepend-inner-icon' props" required>
                <HbSelect
                    rounded
                    v-model="selectField3"
                    :items="['Office','Fax']"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-name="select-rounded"
                    data-vv-as="Select Rounded"
                    :error="errors.has('default.select-rounded')"
                    placeholder="Select Something"
                    prepend-inner-icon="mdi-account"
                />
            </hb-form>

            <hb-form label="HbSelect with 'condensed' and 'box' props, 2 different sizes" required full :auto-layout="false" description="Always use preset sizes for any condensed boxes within a sentence.">
                <v-row no-gutters>
                    <div class="hb-inline-sentence-text">
                        For HbSelect, only use small size:
                    </div>
                    
                    <HbSelect
                        box
                        condensed
                        small
                        v-model="selectField4"
                        :items="['$','%']"
                        v-validate="'required|max:45'"
                        data-vv-scope="default"
                        data-vv-name="select-condensed-box-small"
                        data-vv-as="Select Condensed Box Small"
                        :error="errors.has('default.select-condensed-box-small')"
                    />
                    
                    <div class="hb-inline-sentence-text">
                        or medium size:
                    </div>
                    
                    <HbSelect
                        box
                        condensed
                        medium
                        v-model="selectField5"
                        :items="['Office','Fax','None']"
                        v-validate="'required|max:45'"
                        data-vv-scope="default"
                        data-vv-name="select-condensed-box-medium"
                        data-vv-as="Select Condensed Box Medium"
                        :error="errors.has('default.select-condensed-box-medium')"
                        placeholder="Select"
                    />
                    <div class="hb-inline-sentence-text">
                        within a sentence.
                    </div>
                </v-row>
                <v-row no-gutters>
                    Placeholder for HbSelect expecting just numbers should be: placeholder="0". Placeholder for HbSelect expecting text inputs (i.e. user will be expected to input any combination of numbers and letters) should be empty (i.e. no placeholder).
                </v-row>
            </hb-form>

            <hb-form label="HbAutocomplete" required>
                <HbAutocomplete
                    v-model="autocompleteField1"
                    :items="['USA','Canada']"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-name="country"
                    data-vv-as="Country"
                    :error="errors.has('default.country')"
                    placeholder="Select Country"
                />
            </hb-form>

            <hb-form label="HbAutocomplete with 'box' prop" required>
                <HbAutocomplete
                    box
                    v-model="autocompleteField2"
                    :items="['USA','Canada']"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-name="autocomplete-box"
                    data-vv-as="Autocomplete Box"
                    :error="errors.has('default.autocomplete-box')"
                    placeholder="Select Something"
                />
            </hb-form>

            <hb-form label="HbAutocomplete with 'search' prop" required>
                <HbAutocomplete
                    search
                    v-model="autocompleteField3"
                    :items="['USA','Canada']"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-name="autocomplete-search"
                    data-vv-as="Autocomplete Search"
                    :error="errors.has('default.autocomplete-search')"
                    placeholder="Search"
                />
            </hb-form>

            <hb-form label="HbAutocomplete with 'rounded' prop" required>
                <HbAutocomplete
                    rounded
                    v-model="autocompleteField4"
                    :items="['USA','Canada']"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-name="autocomplete-rounded"
                    data-vv-as="Autocomplete Rounded"
                    :error="errors.has('default.autocomplete-rounded')"
                    placeholder="Select Something"
                />
            </hb-form>

            <hb-form label="HbAutocomplete with 'condensed' and 'box' props, 2 different sizes" required full :auto-layout="false" description="Always use preset sizes for any condensed boxes within a sentence.">
                <v-row no-gutters>
                    <div class="hb-inline-sentence-text">
                        For HbAutocomplete, only use small size:
                    </div>
                    
                    <HbAutocomplete
                        box
                        condensed
                        small
                        v-model="autocompleteField5"
                        :items="['$','%']"
                        v-validate="'required|max:45'"
                        data-vv-scope="default"
                        data-vv-name="autocomplete-condensed-box-small"
                        data-vv-as="Autocomplete Condensed Box Small"
                        :error="errors.has('default.autocomplete-condensed-box-small')"
                    />

                    <div class="hb-inline-sentence-text">
                        or medium size:
                    </div>

                    <HbAutocomplete
                        box
                        condensed
                        medium
                        v-model="autocompleteField6"
                        :items="['Office','Fax','None']"
                        v-validate="'required|max:45'"
                        data-vv-scope="default"
                        data-vv-name="autocomplete-condensed-box-medium"
                        data-vv-as="Autocomplete Condensed Box Medium"
                        :error="errors.has('default.autocomplete-condensed-box-medium')"
                    />

                    <div class="hb-inline-sentence-text">
                        within a sentence.
                    </div>
                    <v-row no-gutters>
                        Placeholder for HbAutocomplete expecting just numbers should be: placeholder="0". Placeholder for HbSelect expecting text inputs (i.e. user will be expected to input any combination of numbers and letters) should be empty (i.e. no placeholder).
                    </v-row>
                </v-row>
            </hb-form>

            <hb-form label="HbTextarea" required full>
                <HbTextarea
                    search
                    v-model="textareaField"
                    v-validate="'required|max:1000'"
                    data-vv-scope="default"
                    data-vv-name="message"
                    data-vv-as="Message"
                    :error="errors.has('default.message')"
                    placeholder="Enter Message"
                    placeholder-fix
                />
            </hb-form>

        <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-form label="HbTextField" required>
    &lt;HbTextField
        v-model="textField1"
        v-validate="'required|max:45|email'"
        data-vv-scope="default"
        data-vv-name="email"
        data-vv-as="Email"
        :error="errors.has('default.email')"
        placeholder="Enter Email"
    />
&lt;/hb-form>

&lt;hb-form label="HbTextField with 'box' prop" required>
    &lt;HbTextField
        box
        v-model="textField2"
        v-validate="'required|max:45'"
        data-vv-scope="default"
        data-vv-name="text-field-box1"
        data-vv-as="Text Field Box 1"
        :error="errors.has('default.text-field-box1')"
        placeholder="Enter Something"
    />
&lt;/hb-form>

&lt;hb-form label="HbTextField with 'box' and 'prepend-inner-icon' props and 'append' slot content" required>
    &lt;hb-text-field
        box
        v-model="textField3"
        v-validate="'required|max:45'"
        data-vv-scope="default"
        data-vv-name="text-field-box2"
        data-vv-as="Text Field Box 2"
        :error="errors.has('default.text-field-box2')"
        placeholder="Enter Something"
        prepend-inner-icon="mdi-account"
    >
        &lt;template v-slot:append>
            ft
        &lt;/template>
    &lt;/hb-text-field>
&lt;/hb-form>

&lt;hb-form label="HbTextField with 'search' prop" required>
    &lt;HbTextField
        search
        v-model="textField4"
        v-validate="'required|max:45'"
        data-vv-scope="default"
        data-vv-name="text-field-search"
        data-vv-as="Text Field Search"
        :error="errors.has('default.text-field-search')"
        placeholder="Search"
    />
&lt;/hb-form>

&lt;hb-form label="HbTextField with 'rounded' prop" required>
    &lt;HbTextField
        rounded
        v-model="textField5"
        v-validate="'required|max:45'"
        data-vv-scope="default"
        data-vv-name="text-field-rounded"
        data-vv-as="Text Field Rounded"
        :error="errors.has('default.text-field-rounded')"
        placeholder="Enter Something"
    />
&lt;/hb-form>

&lt;hb-form label="HbTextField with 'condensed' and 'box' props, 3 different sizes" required full :auto-layout="false" description="Always use preset sizes for any condensed boxes within a sentence.">
    &lt;v-row no-gutters>
        &lt;div class="hb-inline-sentence-text">
            For HbTextField, only use x-small size:
        &lt;/div>
        
        &lt;HbTextField
            condensed
            box
            x-small
            v-model="textField6"
            v-validate="'required|max:45'"
            data-vv-scope="default"
            data-vv-name="text-field-condensed-box-x-small"
            data-vv-as="Text Field Condensed Box X-Small"
            :error="errors.has('default.text-field-condensed-box-x-small')"
            placeholder="0"
        />
        
        &lt;div class="hb-inline-sentence-text">
            small size:
        &lt;/div>
        
        &lt;HbTextField
            condensed
            box
            small
            v-model="textField7"
            v-validate="'required|max:45'"
            data-vv-scope="default"
            data-vv-name="text-field-condensed-box-small"
            data-vv-as="Text Field Condensed Box Small"
            :error="errors.has('default.text-field-condensed-box-small')"
            placeholder="0"
        />
            
        &lt;div class="hb-inline-sentence-text">
            and medium size:
        &lt;/div>
        
        &lt;HbTextField
            condensed
            box
            medium
            v-model="textField8"
            v-validate="'required|max:45'"
            data-vv-scope="default"
            data-vv-name="text-field-condensed-box-medium"
            data-vv-as="Text Field Condensed Box Medium"
            :error="errors.has('default.text-field-condensed-box-medium')"
        />
        
        &lt;div class="hb-inline-sentence-text">
            within a sentence.
        &lt;/div>
    &lt;/v-row>
    &lt;v-row no-gutters>
        Placeholder for HbTextField expecting just numbers should be: placeholder="0". Placeholder for HbTextField expecting text inputs (i.e. user will be expected to input any combination of numbers and letters) should be empty (i.e. no placeholder).
    &lt;/v-row>
&lt;/hb-form>

&lt;hb-form label="HbTextField with 'condensed' and 'box' props, medium size with 'prepend-inner' and 'append' slot content, and 'prepend-inner-icon' prop content" required full :auto-layout="false">
    &lt;v-row no-gutters>
        &lt;div class="hb-inline-sentence-text">
            Prepend Inner Slot "$":
        &lt;/div>
        
        &lt;hb-text-field
            condensed
            box
            medium
            v-model="textField9"
            v-validate="'required|max:45'"
            data-vv-scope="default"
            data-vv-name="text-field-condensed-box-medium-prepend-inner-$"
            data-vv-as="Text Field Condensed Box Medium Prepend Inner $"
            :error="errors.has('default.text-field-condensed-box-medium-prepend-inner-$')"
            placeholder="0"
        >
            &lt;template v-slot:prepend-inner>
                $
            &lt;/template>
        &lt;/hb-text-field>
        
        &lt;div class="hb-inline-sentence-text">
            Append Slot "%":
        &lt;/div>
        
        &lt;hb-text-field
            condensed
            box
            medium
            v-model="textField10"
            v-validate="'required|max:45'"
            data-vv-scope="default"
            data-vv-name="text-field-condensed-box-medium-append-%"
            data-vv-as="Text Field Condensed Box Medium Append %"
            :error="errors.has('default.text-field-condensed-box-medium-append-%')"
            placeholder="0"
        >
            &lt;template v-slot:append>
                %
            &lt;/template>
        &lt;/hb-text-field>
        
        &lt;div class="hb-inline-sentence-text">
            Append Slot "ft":
        &lt;/div>
        
        &lt;hb-text-field
            condensed
            box
            medium
            v-model="textField11"
            v-validate="'required|max:45'"
            data-vv-scope="default"
            data-vv-name="text-field-condensed-box-medium-append-ft"
            data-vv-as="Text Field Condensed Box Medium Append ft"
            :error="errors.has('default.text-field-condensed-box-medium-append-ft')"
            placeholder="0"
        >
            &lt;template v-slot:append>
                ft
            &lt;/template>
        &lt;/hb-text-field>
            
        &lt;div class="hb-inline-sentence-text">
            Prepend Inner Icon Prop "mdi-headset":
        &lt;/div>
        
        &lt;HbTextField
            condensed
            box
            medium
            v-model="textField11"
            v-validate="'required|max:45'"
            data-vv-scope="default"
            data-vv-name="text-field-condensed-box-medium-prepend-inner-icon"
            data-vv-as="Text Field Condensed Box Medium Prepend Inner Icon"
            :error="errors.has('default.text-field-condensed-box-medium-prepend-inner-icon')"
            prepend-inner-icon="mdi-headset"
            placeholder="0"
        />
            
        &lt;div class="hb-inline-sentence-text">
            and then the end of the sentence.
        &lt;/div>
    &lt;/v-row>
&lt;/hb-form>

&lt;hb-form label="HbSelect" required>
    &lt;HbSelect
        v-model="selectField1"
        :items="['Office','Fax']"
        v-validate="'required|max:45'"
        data-vv-scope="default"
        data-vv-name="phone"
        data-vv-as="Phone"
        :error="errors.has('default.phone')"
        placeholder="Select Phone"
    />
&lt;/hb-form>

&lt;hb-form label="HbSelect with 'box' prop" required>
    &lt;HbSelect
        box
        v-model="selectField2"
        :items="['Office','Fax']"
        v-validate="'required|max:45'"
        data-vv-scope="default"
        data-vv-name="select-box"
        data-vv-as="Select Box"
        :error="errors.has('default.select-box')"
        placeholder="Select Something"
    />
&lt;/hb-form>

&lt;hb-form label="HbSelect with 'rounded' and 'prepend-inner-icon' props" required>
    &lt;HbSelect
        rounded
        v-model="selectField3"
        :items="['Office','Fax']"
        v-validate="'required|max:45'"
        data-vv-scope="default"
        data-vv-name="select-rounded"
        data-vv-as="Select Rounded"
        :error="errors.has('default.select-rounded')"
        placeholder="Select Something"
        prepend-inner-icon="mdi-account"
    />
&lt;/hb-form>

&lt;hb-form label="HbSelect with 'condensed' and 'box' props, 2 different sizes" required full :auto-layout="false" description="Always use preset sizes for any condensed boxes within a sentence.">
    &lt;v-row no-gutters>
        &lt;div class="hb-inline-sentence-text">
            For HbSelect, only use small size:
        &lt;/div>
        
        &lt;HbSelect
            box
            condensed
            small
            v-model="selectField4"
            :items="['$','%']"
            v-validate="'required|max:45'"
            data-vv-scope="default"
            data-vv-name="select-condensed-box-small"
            data-vv-as="Select Condensed Box Small"
            :error="errors.has('default.select-condensed-box-small')"
        />
        
        &lt;div class="hb-inline-sentence-text">
            or medium size:
        &lt;/div>
        
        &lt;HbSelect
            box
            condensed
            medium
            v-model="selectField5"
            :items="['Office','Fax','None']"
            v-validate="'required|max:45'"
            data-vv-scope="default"
            data-vv-name="select-condensed-box-medium"
            data-vv-as="Select Condensed Box Medium"
            :error="errors.has('default.select-condensed-box-medium')"
        />
        &lt;div class="hb-inline-sentence-text">
            within a sentence.
        &lt;/div>
        &lt;v-row no-gutters>
            Placeholder for HbSelect expecting just numbers should be: placeholder="0". Placeholder for HbSelect expecting text inputs (i.e. user will be expected to input any combination of numbers and letters) should be empty (i.e. no placeholder).
        &lt;/v-row>
    &lt;/v-row>
&lt;/hb-form>

&lt;hb-form label="HbAutocomplete" required>
    &lt;HbAutocomplete
        v-model="autocompleteField1"
        :items="['USA','Canada']"
        v-validate="'required|max:45'"
        data-vv-scope="default"
        data-vv-name="country"
        data-vv-as="Country"
        :error="errors.has('default.country')"
        placeholder="Select Country"
    />
&lt;/hb-form>

&lt;hb-form label="HbAutocomplete with 'box' prop" required>
    &lt;HbAutocomplete
        box
        v-model="autocompleteField2"
        :items="['USA','Canada']"
        v-validate="'required|max:45'"
        data-vv-scope="default"
        data-vv-name="autocomplete-box"
        data-vv-as="Autocomplete Box"
        :error="errors.has('default.autocomplete-box')"
        placeholder="Select Something"
    />
&lt;/hb-form>

&lt;hb-form label="HbAutocomplete with 'search' prop" required>
    &lt;HbAutocomplete
        search
        v-model="autocompleteField3"
        :items="['USA','Canada']"
        v-validate="'required|max:45'"
        data-vv-scope="default"
        data-vv-name="autocomplete-search"
        data-vv-as="Autocomplete Search"
        :error="errors.has('default.autocomplete-search')"
        placeholder="Search"
    />
&lt;/hb-form>

&lt;hb-form label="HbAutocomplete with 'rounded' prop" required>
    &lt;HbAutocomplete
        rounded
        v-model="autocompleteField4"
        :items="['USA','Canada']"
        v-validate="'required|max:45'"
        data-vv-scope="default"
        data-vv-name="autocomplete-rounded"
        data-vv-as="Autocomplete Rounded"
        :error="errors.has('default.autocomplete-rounded')"
        placeholder="Select Something"
    />
&lt;/hb-form>

&lt;hb-form label="HbAutocomplete with 'condensed' and 'box' props, 2 different sizes" required full :auto-layout="false"  description="Always use preset sizes for any condensed boxes within a sentence.">
    &lt;v-row no-gutters>
        &lt;div class="hb-inline-sentence-text">
            For HbAutocomplete, only use small size:
        &lt;/div>
        
        &lt;HbAutocomplete
            box
            condensed
            small
            v-model="autocompleteField5"
            :items="['$','%']"
            v-validate="'required|max:45'"
            data-vv-scope="default"
            data-vv-name="autocomplete-condensed-box-small"
            data-vv-as="Autocomplete Condensed Box Small"
            :error="errors.has('default.autocomplete-condensed-box-small')"
        />

        &lt;div class="hb-inline-sentence-text">
            or medium size:
        &lt;/div>

        &lt;HbAutocomplete
            box
            condensed
            medium
            v-model="autocompleteField6"
            :items="['Office','Fax','None']"
            v-validate="'required|max:45'"
            data-vv-scope="default"
            data-vv-name="autocomplete-condensed-box-medium"
            data-vv-as="Autocomplete Condensed Box Medium"
            :error="errors.has('default.autocomplete-condensed-box-medium')"
        />

        &lt;div class="hb-inline-sentence-text">
            and then the end of the sentence.
        &lt;/div>
    &lt;/v-row>
    &lt;v-row no-gutters>
        Placeholder for HbAutocomplete expecting just numbers should be: placeholder="0". Placeholder for HbSelect expecting text inputs (i.e. user will be expected to input any combination of numbers and letters) should be empty (i.e. no placeholder).
    &lt;/v-row>
&lt;/hb-form>

&lt;hb-form label="HbTextarea" required full>
    &lt;HbTextarea
        search
        v-model="textareaField"
        v-validate="'required|max:1000'"
        data-vv-scope="default"
        data-vv-name="message"
        data-vv-as="Message"
        :error="errors.has('default.message')"
        placeholder="Enter Message"
        placeholder-fix
    />
&lt;/hb-form>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Commonly Used Input Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Commonly Used Input Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-card title="All Other Props, Slots, &amp; Events" class="my-6 pb-1">
            <hb-data-table
                :headers="vuetifyHeaders"
                :items="vuetifyItems"
                class="pa-6"
            >
                <template v-slot:item.resource="{ item }">
                    <hb-link :href="item.resource" target="_blank">{{ item.resource }}</hb-link>
                </template>
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="snackbar"
            :type="type"
            :list="type === 'error' && list ? errors.items : []"
            :description="description"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemInputs",
        data: function() {
            return {
                active: '',
                type: '',
                list: false,
                description: '',
                snackbar: false,
                textField1: '',
                textField2: '',
                textField3: '',
                textField4: '',
                textField5: '',
                textField6: '',
                textField7: '',
                textField8: '',
                textField9: '',
                textField10: '',
                textField11: '',
                textField12: '',
                selectField1: '',
                selectField2: '',
                selectField3: '',
                selectField4: '',
                selectField5: '',
                autocompleteField1: '',
                autocompleteField2: '',
                autocompleteField3: '',
                autocompleteField4: '',
                autocompleteField5: '',
                autocompleteField6: '',
                textareaField: 'HbForm must use "full" prop when using HbTextarea - textareas must be full width instead of the normal 370px width for inputs.',
                propHeaders: [
                    { text: 'Name', value: 'name', width: '140' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'search', type: 'boolean', default: 'false', description: 'If changed to true, this will apply a box around the input and set a prepended mdi-magnify icon. Only works for HbTextField and HbAutocomplete components.' },
                    { name: 'rounded', type: 'boolean', default: 'false', description: 'If changed to true, this will apply a rounded style to the input.' },
                    { name: 'box', type: 'boolean', default: 'false', description: 'If changed to true, this will apply a boxed style to the input.' },
                    { name: 'condensed', type: 'boolean', default: 'false', description: 'If changed to true, this will condense the input to a lesser height. You should always use this with the "boxed" prop set to true as well because all of our condensed inputs should have a box around them.' },
                    { name: 'x-small', type: 'boolean', default: 'false', description: 'If changed to true, this will set a text field, select, or autocomplete input as an x-small width size. Only works for HbTextField component and should only be used when "condensed" and "box" props are set to true.' },
                    { name: 'small', type: 'boolean', default: 'false', description: 'If changed to true, this will set a text field, select, or autocomplete input as a small size. Only works for HbTextField, HbSelect, and HbAutocomplete components and should only be used when "condensed" and "box" props are set to true.' },
                    { name: 'medium', type: 'boolean', default: 'false', description: 'If changed to true, this will set a text field, select, or autocomplete input as a medium size. Only works for HbTextField, HbSelect, and HbAutocomplete components and should only be used when "condensed" and "box" props are set to true.' },
                    { name: 'disabled', type: 'boolean', default: 'false', description: 'If changed to true, this will apply disabled styling to the input and disable the iteractibility of the input.' },
                    { name: 'prepend-inner-icon', type: 'string', default: 'undefined', description: 'Can set an mdi-code value here (i.e. "mdi-xxxxx") to prepend an icon to the input area.' },
                    { name: 'full', type: 'boolean', default: 'false', description: 'If changed to true, this will make the input go full width to its parent element.' },
                    { name: 'width', type: 'string', default: 'undefined', description: 'Can manually set the width here. You usually should not be using this prop or overriding the default widths. But this prop is here for edge case uses.' },
                    
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                    { name: 'prepend-inner', description: 'This slot is where you can set prepend-inner text such as a "$" sign.' },
                    { name: 'append', description: 'This slot is where you can set append text such as a "%" sign or "ft" abbreviation.' },
                ],
                vuetifyHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                    { text: 'Resource', value: 'resource' },
                ],
                vuetifyItems: [
                    { name: 'all vuetify props, slots, & events', description: 'All the same vuetify props, slots, & events as "<v-text-field>", "<v-select>", "<v-autocomplete">, and "<v-textarea>" can be used. See the vuetify documentation for the list of available props, slots, & events.', resource: 'https://v2.vuetifyjs.com/en/' },
                ],
            };
        },
        methods: {
            validateOpenForm(){
                this.$validator.validateAll('default').then(valid => {
                    if (!valid) {
                        this.list = true;
                        this.description = "There are errors in your form, correct them before continuing."
                        this.type = 'error';
                        this.snackbar = true;
                    } else {
                        this.description = "All fields passed validation. This message is not intended for a normal user to see. It is just here to notify developers that the validation was successful."
                        this.type = 'success';
                        this.snackbar = true;
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
